import React from 'react';

export const themes = {
    NORDR: 'nordr',
    FOLKHEM: 'folkhem',
};
// TODO: Veidekke should be default
//export const ThemeContext = React.createContext(themes.VEIDEKKEBOSTAD);
// export const ThemeContext = React.createContext(themes.NORDR);
export const ThemeContext = React.createContext(themes.FOLKHEM);
