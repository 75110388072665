import React from 'react'; import PropTypes from 'prop-types';
//import classNames from 'classnames';
import dynamic from 'next/dynamic';
//import styles from './Icon.module.scss';

const AnnualRing = dynamic(() =>
    import('../../public/svg/annual-ring.svg').then((mod) => mod.ReactComponent)
);

const Avatar = dynamic(() =>
    import('../../public/svg/avatar.svg').then((mod) => mod.ReactComponent)
);

const LogoNordrLight = dynamic(() =>
    import('../../public/svg/logo-nordr-light.svg').then((mod) => mod.ReactComponent)
);

const LogoNordrDark = dynamic(() =>
    import('../../public/svg/logo-nordr-dark.svg').then((mod) => mod.ReactComponent)
);

const LogoFolkhem = dynamic(() =>
    import('../../public/svg/logo-folkhem.svg').then((mod) => mod.ReactComponent)
);

const FacebookIcon = dynamic(() =>
    import('../../public/svg/icon-facebook.svg').then((mod) => mod.ReactComponent)
);
const TwitterIcon = dynamic(() =>
    import('../../public/svg/icon-twitter.svg').then((mod) => mod.ReactComponent)
);
const InstagramIcon = dynamic(() =>
    import('../../public/svg/icon-instagram.svg').then((mod) => mod.ReactComponent)
);
const LinkedinIcon = dynamic(() =>
    import('../../public/svg/icon-linkedin.svg').then((mod) => mod.ReactComponent)
);

const MaterialFilterIcon = dynamic(() =>
    import('../../public/svg/material-design/content/ic_filter_list_24px.svg').then((mod) => mod.ReactComponent)
);

const MaterialResetIcon = dynamic(() =>
    import('../../public/svg/material-design/av/ic_loop_24px.svg').then((mod) => mod.ReactComponent)
);

const MaterialPlayIcon = dynamic(() =>
    import('../../public/svg/material-design/av/ic_play_arrow_24px.svg').then((mod) => mod.ReactComponent)
);

const FilterIcon = dynamic(() =>
    import('../../public/svg/icon-filter.svg').then((mod) => mod.ReactComponent)
);

const FilterResetIcon = dynamic(() =>
    import('../../public/svg/icon-filter-reset.svg').then((mod) => mod.ReactComponent)
);

const ImageIcon = dynamic(() =>
    import('../../public/svg/icons-bildvy-nordr.svg').then((mod) => mod.ReactComponent)
);
const ListIcon = dynamic(() =>
    import('../../public/svg/icon-list-view-selector-list.svg').then((mod) => mod.ReactComponent)
);

const MaterialImageIcon = dynamic(() =>
    import('../../public/svg/material-design/image/ic_image_24px.svg').then((mod) => mod.ReactComponent)
);

const MaterialListIcon = dynamic(() =>
    import('../../public/svg/material-design/action/ic_list_24px.svg').then((mod) => mod.ReactComponent)
);


const BuildingIcon = dynamic(() =>
    import('../../public/svg/icon-building.svg').then((mod) => mod.ReactComponent)
);

const GalleryIcon = dynamic(() =>
    import('../../public/svg/icon-image.svg').then((mod) => mod.ReactComponent)
);

const GalleryIconNordr = dynamic(() =>
    import('../../public/svg/icon-image-nordr.svg').then((mod) => mod.ReactComponent)
);

const ApartmentsIcon = dynamic(() =>
    import('../../public/svg/icon-apartments.svg').then((mod) => mod.ReactComponent)
);

const ApartmentsIconNordr = dynamic(() =>
    import('../../public/svg/icon-apartments-nordr-blue.svg').then((mod) => mod.ReactComponent)
);

const MailIcon = dynamic(() =>
    import('../../public/svg/icon-mail.svg').then((mod) => mod.ReactComponent)
);

const PhoneIcon = dynamic(() =>
    import('../../public/svg/icon-phone.svg').then((mod) => mod.ReactComponent)
);

export const allIcons = {
    AnnualRing,
    Avatar,
    LogoNordrLight,
    LogoNordrDark,
    LogoFolkhem,
    FacebookIcon,
    TwitterIcon,
    InstagramIcon,
    LinkedinIcon,
    MaterialFilterIcon,
    MaterialResetIcon,
    MaterialPlayIcon,
    FilterIcon,
    FilterResetIcon,
    ImageIcon,
    ListIcon,
    MaterialImageIcon,
    MaterialListIcon,
    BuildingIcon,
    GalleryIcon,
    GalleryIconNordr,
    ApartmentsIcon,
    ApartmentsIconNordr,
    MailIcon,
    PhoneIcon,
}


const Icon = ({ name, className, ...props }) => {
    const Svg = allIcons[name];

    if (!Svg) {
        throw Error(`Cannot found icon ${name}`);
    }

    return (
        <Svg className={className}/>
    );
}

Icon.propTypes = {
    name: PropTypes.string,
}

Icon.defaultProps = {
    name: null,
}

export default Icon;
