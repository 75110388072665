import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import Breadcrumbs from '../Breadcrumbs';
import i18n from '../../i18n';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesNordr from './Header.nordr.module.scss';
import stylesFolkhem from './Header.folkhem.module.scss';

const SERVICE_MICROBIZZ = 'SERVICE_MICROBIZZ';

const getStyles = (theme) =>
    ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]);

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    toggle = () => {
        const theme = this.context;
        const styles = getStyles(theme);

        this.setState(
            {
                open: !this.state.open,
            },
            () => {
                if (!this.state.open) {
                    document.documentElement.classList.remove(
                        styles['Header--NavOpen']
                    );
                } else {
                    document.documentElement.classList.add(
                        styles['Header--NavOpen']
                    );
                }
            }
        );
    };

    render() {
        const { navItems, subNavItems, startPage, breadcrumbs, user, layout } =
            this.props;

        const theme = this.context;
        const styles = getStyles(theme);

        const hasBreadcrumbs =
            breadcrumbs && breadcrumbs.items && breadcrumbs.items.length > 1;
        const isFolkhemTheme = theme === themes.FOLKHEM;

        let logo = (
            <Icon
                name="LogoNordrLight"
                className={styles['Header__LogoIcon']}
            />
        );

        if (isFolkhemTheme) {
            logo = (
                <Icon
                    name="LogoFolkhem"
                    className={styles['Header__LogoIcon']}
                />
            );
        }

        return (
            <div>
                <div
                    className={
                        'Header ' +
                        styles['Header'] +
                        ' ' +
                        styles[`Header--Layout-${layout}`]
                    }>
                    <style global jsx>{`
                        .modal-open .Header {
                            display: none;
                        }
                    `}</style>
                    <div className={styles['Header__Wrapper']}>
                        <div className={styles['Header__Container']}>
                            <a
                                href={startPage}
                                aria-label={i18n.t('header.logo')}
                                className={styles['Header__Logo']}>
                                {logo}
                            </a>
                            <div
                                className={styles['Header__Hamburger']}
                                onClick={this.toggle}>
                                <span />
                                <span />
                                <span />
                                <span />
                            </div>
                        </div>

                        <div className={styles['Header__NavContainer']}>
                            <div
                                className={classNames(
                                    styles['Header__NavContent'],
                                    {
                                        [styles['Header__NavContent--Active']]:
                                            this.state.open,
                                    }
                                )}>
                                <div className={styles['Header__NavScroll']}>
                                    {navItems && navItems.length >= 0 && (
                                        <nav className={styles['Header__Nav']}>
                                            {navItems.map((item, i) => (
                                                <a
                                                    key={i}
                                                    className={classNames(
                                                        styles[
                                                            'Header__NavLink'
                                                        ],
                                                        {
                                                            [styles[
                                                                'Header__NavLink--Active'
                                                            ]]: item.active,
                                                        }
                                                    )}
                                                    href={item.href}
                                                    target={item.target}>
                                                    {item.title}
                                                </a>
                                            ))}
                                        </nav>
                                    )}

                                    <div
                                        className={
                                            styles['Header__SubNavWrapper']
                                        }>
                                        {!isFolkhemTheme && user && (
                                            <Login theme={theme} {...user} />
                                        )}

                                        {subNavItems &&
                                            subNavItems.length >= 0 && (
                                                <div
                                                    className={
                                                        styles['Header__SubNav']
                                                    }>
                                                    {subNavItems.map(
                                                        (item, i) => (
                                                            <a
                                                                href={item.href}
                                                                key={i}
                                                                className={classNames(
                                                                    styles[
                                                                        'Header__NavLink'
                                                                    ],
                                                                    styles[
                                                                        'Header__NavLink--SubLink'
                                                                    ],
                                                                    {
                                                                        [styles[
                                                                            'Header__NavLink--SubLinkActive'
                                                                        ]]:
                                                                            item.active,
                                                                    }
                                                                )}>
                                                                {item.title}
                                                            </a>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {hasBreadcrumbs && (
                    <div
                        className={
                            styles['Header__Breadcrumb'] +
                            ' ' +
                            styles['Header__Breadcrumb--Layout-' + layout]
                        }>
                        <Breadcrumbs
                            {...breadcrumbs}
                            modifiers={['Header', 'Layout-' + layout]}
                        />
                    </div>
                )}
            </div>
        );
    }
}

Header.propTypes = {
    navItems: PropTypes.array,
    subNavItems: PropTypes.array,
    startPage: PropTypes.string,
    breadcrumbs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    user: PropTypes.shape({
        firstName: PropTypes.string,
        profile: PropTypes.shape({
            href: PropTypes.string,
        }),
        login: PropTypes.shape({
            href: PropTypes.string,
        }),
        logout: PropTypes.shape({
            href: PropTypes.string,
        }),
    }),
};

Header.defaultProps = {
    navItems: [],
    subNavItems: [],
    startPage: '/',
};

Header.contextType = ThemeContext;

const Login = ({
    profile,
    login,
    logout,
    firstName,
    services,
    microbizzUrl,
    links,
    theme,
}) => {
    const styles = getStyles(theme);
    return (
        <div className={styles['Header__Login'] + ' Header__Login'}>
            {firstName ? (
                <span className={styles['Header__LoggedIn']}>
                    {firstName}
                    <div className={styles['Header__LoggedInModal']}>
                        <div className={styles['Header__LoggedInNav']}>
                            {links.map((link, index) => (
                                <a
                                    key={index}
                                    className={
                                        styles['Header__LoggedInNavItem']
                                    }
                                    {...link.link}>
                                    {link.text}
                                </a>
                            ))}
                            {services.includes(SERVICE_MICROBIZZ) && (
                                <a
                                    className={
                                        styles['Header__LoggedInNavItem']
                                    }
                                    href={microbizzUrl}>
                                    {i18n.t('myPages.toMicrobizz')}
                                </a>
                            )}
                            <a
                                className={styles['Header__LoggedInNavItem']}
                                href={logout.href}>
                                {i18n.t('myPages.logOut')}
                            </a>
                        </div>
                    </div>
                </span>
            ) : (
                <a className={styles['Header__NavLink']} href={login.href}>
                    {i18n.t('myPages.myPages')}
                </a>
            )}
        </div>
    );
};

Login.propTypes = {
    firstName: PropTypes.string,
    profile: PropTypes.shape({
        href: PropTypes.string,
    }),
    login: PropTypes.shape({
        href: PropTypes.string,
    }),
    logout: PropTypes.shape({
        href: PropTypes.string,
    }),
    services: PropTypes.arrayOf(PropTypes.string),
    microbizzUrl: PropTypes.string,
    layout: PropTypes.string,
};

Login.defaultProps = {
    login: {},
    profile: {},
    logout: {},
    services: [],
    links: [],
    layout: '',
};

export default Header;
