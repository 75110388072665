/**
 * LinkList
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { VelocityComponent } from 'velocity-react';
import { ThemeContext, themes } from '../../../themes/ThemeContext';
import stylesNordr from './LinkList.nordr.module.scss';
import stylesFolkhem from './LinkList.folkhem.module.scss';

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

class LinkList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    toggle = () => {
        this.setState({
            open: !this.state.open,
        });
    };

    render() {
        const { items, modifier } = this.props;
        const firstLink = items[0];
        const globalTheme = this.context;
        const styles = getStyles(globalTheme);

        let classes = classNames(styles["LinkList"], {
            [styles["LinkList--Open"]]: this.state.open,
            [styles["LinkList--"+modifier]]: !!modifier,
        });

        return (
            <div className={classes}>
                {firstLink && (
                    <div className={styles["LinkList__Headline"]} onClick={this.toggle}>
                        {firstLink.title}

                        {items && items.length > 0 && (
                            <span className={styles["LinkList__Button"]} />
                        )}
                    </div>
                )}

                <VelocityComponent
                    animation={this.state.open ? 'slideDown' : 'slideUp'}
                    duration={250}
                >
                    <ul className={styles["LinkList__List"]}>
                        {items.map(function(item, i) {
                            return (
                                <li className={styles["LinkList__ListItem"]} key={i}>
                                    <a
                                        className={styles["LinkList__Link"]}
                                        href={item.href}
                                        target={item.target}>
                                        {item.title}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </VelocityComponent>
            </div>
        );
    }
}

LinkList.propTypes = {
    items: PropTypes.array,
    modifier: PropTypes.string,
};

LinkList.defaultProps = {
    items: [],
};

LinkList.contextType = ThemeContext;

export default LinkList;
