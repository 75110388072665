/**
 * Breadcrumbs
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesNordr from './Breadcrumbs.nordr.module.scss';
import stylesFolkhem from './Breadcrumbs.folkhem.module.scss';

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

class Breadcrumbs extends React.PureComponent {
    render() {
        const { items, modifier, modifiers } = this.props;

        const theme = this.context;
        const styles = getStyles(theme);

        let classes = classNames(
            styles["Breadcrumbs"],
            modifiers.map(x => styles['Breadcrumbs--'+x]),
            {
                [styles[`Breadcrumbs--${modifier}`]]: !!modifier,
            }
        );

        return (
            <nav className={classes} aria-label={modifier === 'Footer' ? 'Brödsmulor sidfot' : `Brödsmulor`}>
                <div className={styles["Breadcrumbs__Wrapper"]}>
                    {items.map((item, index) => {
                        if (index < items.length - 1) {
                            return (
                                <div key={index} className={styles["Breadcrumbs__Item"]}>
                                    <a
                                        className={styles["Breadcrumbs__Link"]}
                                        href={item.href}>
                                        {item.title}
                                    </a>
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    key={index}
                                    className={styles["Breadcrumbs__Item"] + " " + styles["Breadcrumbs__Item--Last"]}>
                                    <span className={styles["Breadcrumbs__Link"] + " " + styles["Breadcrumbs__Link--Last"]}>
                                        {item.title}
                                    </span>
                                </div>
                            );
                        }
                    })}
                </div>
            </nav>
        );
    }
}

Breadcrumbs.propTypes = {
    items: PropTypes.array,
    modifier: PropTypes.string,
    modifiers: PropTypes.arrayOf(PropTypes.string),
};

Breadcrumbs.defaultProps = {
    items: [],
    modifiers: [],
};

Breadcrumbs.contextType = ThemeContext;

export default Breadcrumbs;
