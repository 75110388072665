/**
 * SocialMedia
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import { ThemeContext, themes } from '../../../themes/ThemeContext';
import stylesNordr from './SocialMedia.nordr.module.scss';
import stylesFolkhem from './SocialMedia.folkhem.module.scss';


const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

const SocialMedia = (props) => {
    const theme = useContext(ThemeContext);
    const styles = getStyles(theme);

    return (
        <div className={styles["SocialMedia"]}>
            <div className={styles["SocialMedia__Links"]}>
                {props.facebook && (
                    <a
                        href={props.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles["SocialMedia__Link"]}>
                        <span className="sr-only">Facebook</span>
                        <Icon name="FacebookIcon" className={styles["SocialMedia__Icon"] + " " + styles["SocialMedia__Icon--Facebook"]} />
                    </a>
                )}

                {props.linkedin && (
                    <a
                        href={props.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles["SocialMedia__Link"]}>
                        <span className="sr-only">LinkedIn</span>
                        <Icon name="LinkedinIcon" className={styles["SocialMedia__Icon"] + " " + styles["SocialMedia__Icon--Linkedin"]} />
                    </a>
                )}

                {props.twitter && (
                    <a
                        href={props.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles["SocialMedia__Link"]}>
                        <span className="sr-only">Twitter</span>
                        <Icon name="TwitterIcon" className={styles["SocialMedia__Icon"] + " " + styles["SocialMedia__Icon--Twitter"]} />
                    </a>
                )}

                {props.instagram && (
                    <a
                        href={props.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles["SocialMedia__Link"]}>
                        <span className="sr-only">Instagram</span>
                        <Icon name="InstagramIcon" className={styles["SocialMedia__Icon"] + " " + styles["SocialMedia__Icon--Instagram"]} />
                    </a>
                )}
            </div>
        </div>
    );
}

SocialMedia.propTypes = {
    facebook: PropTypes.string,
    linkedin: PropTypes.string,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
};

export default SocialMedia;
