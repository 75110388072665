import i18next from 'i18next';

import sv from './translations/sv.json';

export class I18n {
    constructor() {
        i18next.init(
            {
                lng: 'sv',
                resources: {
                    sv: {
                        translation: sv,
                    },
                },
            },
            (e, t) => {
                this.t = t;
            }
        );
    }
}

export default new I18n();
