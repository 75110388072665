import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesNordr from './Btn.nordr.module.scss';
import stylesFolkhem from './Btn.folkhem.module.scss';

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

const Btn = ({ element, className, ...props }) => {
    const Element = element;
    const theme = useContext(ThemeContext);
    const styles = getStyles(theme);

    className = className.split(" ");
    className = className.filter(x => x);
    className = className.map(x => {
        if (x.indexOf("Btn") === 0) {
            return styles[x] ?? x;
        }

        return x;
    });
    className = className.join(" ");

    return (
        <Element className={className} {...props} />
    );
};

Btn.propTypes = {
    element: PropTypes.string,
    className: PropTypes.string,
};

Btn.defaultProps = {
    element: 'button',
    className: 'Btn',
};

export default Btn;
