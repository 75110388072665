import React from 'react';
import dynamic from 'next/dynamic';
import { ThemeContext, themes } from './ThemeContext';

const ThemeNordr = dynamic(() => import('./ThemeNordr'));
const ThemeFolkhem = dynamic(() => import('./ThemeFolkhem'));

const Theme = ({ name, children, ...props }) => {
    let Styling = null;

    if (name === themes.NORDR) {
        Styling = ThemeNordr;
    } else if (name === themes.FOLKHEM) {
        Styling = ThemeFolkhem;
    }

    return (
        <ThemeContext.Provider value={name}>
            <Styling />
            {children}
        </ThemeContext.Provider>
    )
}

export default Theme;
