import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LinkList from './LinkList';
import SocialMedia from './SocialMedia/SocialMedia';
import Breadcrumbs from '../Breadcrumbs';
import CookieConsent from '../CookieConsent';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesNordr from './Footer.nordr.module.scss';
import stylesFolkhem from './Footer.folkhem.module.scss';

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

class Footer extends React.Component {
    render() {
        const {
            linksAboveFooter,
            footnote,
            linkList,
            socialMedia,
            contact,
            startPage,
            breadcrumbs,
            layout,
            modifiers,
            siteSetting,
        } = this.props;

        const globalTheme = this.context;
        const styles = getStyles(globalTheme);

        const lastItems =
            linkList && linkList.length > 4 ? linkList.pop() : null;

        let logoLabel = "Nordr";
        if (globalTheme === themes.FOLKHEM) {
            logoLabel = "Folkhem"
        }

        return (
            <footer
                className={classNames(
                    styles["Footer"],
                    modifiers
                        .filter((x) => x)
                        .map((x) => styles[`Footer--${x}`])
                )}
            >
                {layout !== 'our-homes' && linksAboveFooter && (
                    <div className={styles["Footer__Above"]}>
                        <ul className={styles["Footer__MainLinkList"]}>
                            {linksAboveFooter.map((link) => {
                                return (
                                    <li
                                        key={link.href}
                                        className={styles["Footer__MainLinkListItem"]}>
                                        <a
                                            className={styles["Footer__MainLinkListItemLink"]}
                                            href={link.href}
                                            target={link.target}>
                                            <span className={styles["Footer__MainLinkListItemLinkText"]}>
                                                {link.title}
                                            </span>
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}

                <div className={styles["Footer__Dark"]}>
                    <div className={styles["Footer__Wrap"]}>
                        <div className={styles["Footer__Container"]}>

                            {linkList && linkList.length > 0 && (
                                <div className={styles["Footer__DropdownContainer"]}>
                                    {linkList.map(function(item, i) {
                                        if (i > 3) {
                                            return null;
                                        }

                                        return (
                                            <div
                                                className={styles["Footer__DropdownItem"]}
                                                key={i}>
                                                <LinkList items={item} />

                                                {i === 3 && lastItems && (
                                                    <LinkList
                                                        items={lastItems}
                                                        modifier={'Last'}
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                            {contact && (
                                <div className={styles["Footer__Contact"]}>
                                    {contact.title && (
                                        <h2 className={styles["Footer__ContactTitle"]}>
                                            {contact.title}
                                        </h2>
                                    )}

                                    {contact.address && (
                                        <div
                                            className={styles["Footer__ContactAddress"]}
                                            dangerouslySetInnerHTML={{
                                                __html: contact.address,
                                            }}
                                        />
                                    )}

                                    {contact.contactInfo && (
                                        <div className={styles["Footer__ContactInfo"]}>
                                            {contact.contactInfo}
                                        </div>
                                    )}
                {!!siteSetting?.cookieConsent?.title && (
                    <CookieConsent {...siteSetting.cookieConsent} />
                )}
                                </div>
                            )}
                        </div>

                        {breadcrumbs &&
                            breadcrumbs.items &&
                            breadcrumbs.items.length > 1 && (
                            <div className={styles["Footer__Breadcrumbs"]}>
                                <Breadcrumbs
                                    {...breadcrumbs}
                                    modifier={"Footer"}
                                />
                            </div>
                        )}

                        <div className={styles["Footer__CompanyInfo"]}>
                            <div className={styles["Footer__SocialMedia"]}>
                                <SocialMedia {...socialMedia} />
                            </div>

                            {footnote && (
                                <div className={styles["Footer__Footnote"]}>
                                    {footnote}
                                </div>
                            )}

                            <div className={styles["Footer__LogoContainer"]}>
                                <a href={startPage} className={styles["Footer__Logo"]}>
                                    <span className="sr-only">{logoLabel}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

Footer.propTypes = {
    linksAboveFooter: PropTypes.array,
    footnote: PropTypes.string,
    linkList: PropTypes.array,
    socialMedia: PropTypes.object,
    contact: PropTypes.object,
    startPage: PropTypes.string,
    breadcrumbs: PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
        }))
    }),
    layout: PropTypes.string,
    modifiers: PropTypes.arrayOf(PropTypes.string),
};

Footer.defaultProps = {
    links: [],
    startPage: '/',
    modifiers: [],
};

Footer.contextType = ThemeContext;

export default Footer;
